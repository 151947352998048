
import {
  ConsultantContractMilestone,
  MissionStaffingApplication,
  MissionLifecycle,
  DeleteConsultantContractMilestoneMutation,
  DeleteConsultantContractMilestoneMutationMutation,
  ConsultantContractFragmentFragment,
} from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import UserCard from "@/views/missions/details/UserCard.vue"
import EditConsultantContractMilestoneModal from "@/components/missions/EditConsultantContractMilestoneModal.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import EventBus from "@/components/EventBus"
import ActivityTimeline from "@/components/widgets/timeline/ActivityTimeline.vue"
import DocumentCard from "@/components/content/DocumentCard.vue"
import ConsultingAgreementTemplate from "@/components/templates/ConsultingAgreementTemplate.vue"
import Printable from "@/components/layout/Printable.vue"
import { ContractingEntities } from "@/constants"
import { useConsultantContractState } from "@/hooks/useConsultantContractState"
import LogoSvg from "@/components/widgets/icons/LogoSvg.vue"
import { useCountry } from "@/hooks/useCountry"

@Component({
  components: {
    UserCard,
    Printable,
    DocumentCard,
    ActivityTimeline,
    ConsultingAgreementTemplate,
    EditConsultantContractMilestoneModal,
    LogoSvg,
  },
})
export default class ConsultantContractSummary extends Vue {
  @Prop({ required: true }) readonly contract!: ConsultantContractFragmentFragment
  @Prop({ required: false }) readonly application?: MissionStaffingApplication
  @Prop({ required: false }) readonly mission?: MissionLifecycle
  @Prop() readonly refetchQueries!: RefetchQueryDescription

  activeMilestone: ConsultantContractMilestone | null = null
  showConsultantDeleteMilestoneModal = false
  deleteMilestoneActionLoading = false
  showEditMilestoneModal = false
  showContractDocumentPrintable = false

  readonly ContractingEntities = ContractingEntities

  get missionLifecycle() {
    return this.contract.missionLifecycle || this.mission
  }

  get payments() {
    return this.contract!.application!.missionStaffingPosition.consultantContractPayments
  }

  get paymentsSum(): string {
    return this.moneyFormat(this.payments.reduce((sum, payment) => sum + payment.amount.amount, 0))
  }

  get contractState() {
    if (!this.contract) return "developing"

    const { state } = useConsultantContractState(this.contract.state)
    return state
  }

  showEditConsultantContractMilestoneModal(milestone: ConsultantContractMilestone) {
    this.activeMilestone = milestone
    this.showEditMilestoneModal = true
  }

  showConsultantDeleteMilestoneDialog(milestone: ConsultantContractMilestone) {
    this.activeMilestone = milestone
    this.showConsultantDeleteMilestoneModal = true
  }

  onApprovalComplete() {
    EventBus.$emit("update-contract-detail-query")
  }

  async onEditMilestone() {
    this.activeMilestone = null
    this.showEditMilestoneModal = false
    this.addSuccess(`Milestone updated!`)
    this.$emit("save")
  }

  get country() {
    if (!this.missionLifecycle.projectLocationCountryCode) return null
    const { getCountryByCode } = useCountry()
    return getCountryByCode(this.missionLifecycle.projectLocationCountryCode)
  }

  async onDeleteConsultantContractMilestone() {
    if (!this.activeMilestone) return

    const mutation = DeleteConsultantContractMilestoneMutation,
      variables = {
        id: this.activeMilestone?.id,
      }

    this.deleteMilestoneActionLoading = true

    const result = await this.mutate<DeleteConsultantContractMilestoneMutationMutation>({
      mutation: mutation,
      variables: {
        ...variables,
      },
      done: () => {
        this.deleteMilestoneActionLoading = false
      },
      refetchQueries: this.refetchQueries,
    })

    if (result.data && !result.data.deleteConsultantContractMilestone.error) {
      this.addSuccess(`Milestone deleted!`)
      this.showConsultantDeleteMilestoneModal = false
      this.activeMilestone = null
      this.$emit("save")
    }
  }
}
